<template>
	<div class="clients wrap">
		<h2>Featured Work</h2>
		<p>
			Some of the brands I could work for in the past years. All are clients of
			the agencies I've worked for.
		</p>

		<ul
			@mouseover="stopTimer"
			@mouseleave="startTimer"
		>
			<li
				v-for="({name, slug}, index) in items"
				:key="name"
				:style="{animationDelay: `${index * 0.25}s`}"
				:title="name"
				data-animation="fadeUp"
			>
				<svg>
					<use :xlink:href="`#${slug}--sprite`" />
				</svg>
			</li>
		</ul>
	</div>
</template>

<script>
import clients from '@/data/clients.json';
import {shuffle} from '@schascha/brabbelback';

const
	duration = 3000,
	maxItems = 4
;

export default {
	data() {
		return {
			active: 0,
			clients: shuffle(clients.map(el => {
				el.icon = require(`@/assets/clients/${el.slug}.svg?sprite`);
				return el;
			})),
			items: [],
			timer: ''
		};
	},
	mounted() {
		this.getItems();
		this.startTimer();
	},
	onUnmounted() {
		this.stopTimer();
	},
	methods: {
		getItems() {
			const
				{active, clients} = this
			;

			let
				next = active + maxItems
			;

			this.items = clients.slice(active, next);

			// Restart array loop
			if (next >= clients.length) {
				next = next - clients.length;
				this.items = [...this.items, ...clients.slice(0, next)];
			}

			this.active = next;
		},
		stopTimer() {
			window.clearInterval(this.timer);
		},
		startTimer() {
			this.timer = window.setInterval(this.getItems, duration);
		}
	}
};
</script>

<style lang="scss" scoped>
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: var(--space-10) 0;
		padding: 0;
		list-style: none;

		@include mq($break) {
			flex-wrap: nowrap;
		}
	}

	li {
		flex-basis: 50%;
		padding: 0.5rem var(--space-5);
		max-width: 200px;

		@include mq($break) {
			flex-basis: 25%;
		}
	}

	svg {
		width: 100%;
		max-width: 120px;
		height: 100%;
		max-height: 60px;
		fill: var(--color-highlight);
	}
</style>
